import { css } from '@emotion/css';
import { globalCss, mobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const avalonHeaderCss = css`
  /* TODO: Placeholder. */
`;

export const avalonSdsmHeaderCss = css`
  backdrop-filter: blur(4px);

  /* TODO: Make a concrete marker for this. */
  & > div {
    background-color: rgba(0, 0, 0, 0.5);
  }

  ${mobileMediaQuery} {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const avalonBodyDefaultBackgroundColorCss = globalCss`
body {
  background-color: Black
}
`;
