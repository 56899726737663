import { cx } from '@emotion/css';
import { getDprSrcSetSettingsByHeight } from '@snapchat/mw-common/client';
import { useContentfulImages } from '@snapchat/mw-global-components';
// TODO: Fix this. Exporting `titleCss` to mean block title is horrendous!
import { p1Css, Picture, titleCss, Video } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { CallToAction } from '../../../../components/CallToAction';
import { combineImageSources } from '../../../../utils/combineImageSources';
import {
  renderRichText,
  renderRichTextMultiLineWithMarkings,
} from '../../../../utils/renderText/renderRichText';
import type { ValuePropsContentDataProps } from './types';
import {
  containerCss,
  fourThreeAspectMediaCss,
  mediaCss,
  valuePropsBodyContentCss,
  valuePropsBodyCss,
  valuePropsContentCss,
  valuePropsTitleCss,
} from './ValuePropsContent.styles';

export const ValuePropsContent: FC<ValuePropsContentDataProps> = props => {
  const { getImageSources } = useContentfulImages();

  const { asset, body, subtitle, title, mediaAspectRatio, callToAction } = props;

  const desktopMedia = asset.media;
  const mobileMedia = asset.mobileMedia;
  const backgroundMediaType = asset.__typename;

  let imgSrcs;

  if (backgroundMediaType === 'Image') {
    imgSrcs = combineImageSources({
      desktop: getImageSources(
        desktopMedia.url,
        getDprSrcSetSettingsByHeight(800, desktopMedia.height ?? 0)
      ),
      mobile: getImageSources(
        mobileMedia?.url,
        getDprSrcSetSettingsByHeight(800, mobileMedia?.height ?? 0)
      ),
    });
  }

  return (
    <div className={cx('avalon-value-props-content', containerCss)}>
      {title && (
        <h2 className={cx(titleCss, valuePropsTitleCss)}>
          {renderRichTextMultiLineWithMarkings(title)}
        </h2>
      )}

      {subtitle && <p className={cx(p1Css, valuePropsTitleCss)}>{subtitle}</p>}
      <div className={valuePropsContentCss}>
        {backgroundMediaType === 'Image' ? (
          <Picture
            altText={desktopMedia.description}
            imgSrcs={imgSrcs}
            imgClassName={cx(mediaCss, { [fourThreeAspectMediaCss]: mediaAspectRatio === '4:3' })}
          />
        ) : backgroundMediaType === 'Video' ? (
          <Video
            isBackgroundVideo
            source={desktopMedia.url}
            sourceType={desktopMedia.contentType}
            mobileSource={mobileMedia?.url}
            mobileSourceType={mobileMedia?.contentType}
            className={cx(mediaCss, { [fourThreeAspectMediaCss]: mediaAspectRatio === '4:3' })}
          />
        ) : null}
        {body && (
          <div className={valuePropsBodyCss}>
            <div className={valuePropsBodyContentCss}>{renderRichText(body)}</div>
          </div>
        )}
      </div>

      {callToAction && <CallToAction {...callToAction} />}
    </div>
  );
};
