import { css } from '@emotion/css';

export const firstMosaicCss = css`
  padding-top: 26px;
`;

export const gridCardContainerCss = css`
  padding-top: 4px;
  padding-bottom: 4px;
`;
