import { gql } from '@apollo/client';

import { fragments as imageFragments } from '../../../../components/Image';
import { fragments as videoFragments } from '../../../../components/Video';
import type { ContentfulSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import type { RichText } from '../../../../types/RichText';
import type { MosaicGridProps } from '../MosaicGridCard/types';

export interface MosaicGridBlockData extends ContentfulSysProps {
  title?: RichText | string;
  subtitle?: RichText | string;
  anchor?: string;
  contentsCollection?: Items<MosaicGridProps>;
}

export interface MosaicBlockContentDataHandlerProps {
  mosaicGridBlock: MosaicGridBlockData;
}

export const mosaicGridContentFragment = gql`
  fragment MosaicGridCardAll on MosaicGridCard {
    title
    body {
      json
    }
    textAlignment
    media {
      ... on Video {
        ...VideoAll
      }
      ... on Image {
        ...ImageAll
      }
    }
    mediaDirection
  }
  ${imageFragments.all}
  ${videoFragments.all}
`;
