import { getDprSrcSetSettingsByHeight } from '@snapchat/mw-common/client';
import { useContentfulImages } from '@snapchat/mw-global-components';
import { Alignment, Block, Media, useIsMobile } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { combineImageSources } from '../../../../utils/combineImageSources';
import { parseMedia } from '../../../../utils/media';
import {
  renderRichText,
  renderRichTextMultiLineWithMarkings,
} from '../../../../utils/renderText/renderRichText';
import { AvalonCard } from '../AvalonCard/AvalonCard';
import type { AvalonNoScrollBlockProps } from './AvalonScrollSection.types';

/** Custom component responsible for non-animated experience for mobile devices, etc. */
export const AvalonNoScrollBlock: FC<AvalonNoScrollBlockProps> = ({
  title,
  subtitle,
  textNodes,
}) => {
  const isMobile = useIsMobile();
  const { getImageSources } = useContentfulImages();

  return (
    <Block
      className="avalon-no-scroll-block"
      title={title}
      maxColumns={1}
      subtitle={renderRichTextMultiLineWithMarkings(subtitle)}
      titleAlignmentMobile="Center"
    >
      {textNodes?.map(({ sys, title, description, thumbnail }, i) => {
        const imgSrcs = combineImageSources({
          desktop: getImageSources(
            thumbnail?.media.url,
            getDprSrcSetSettingsByHeight(800, thumbnail?.media.height ?? 0)
          ),
          mobile: getImageSources(
            thumbnail?.mobileMedia?.url ?? thumbnail?.media?.url,
            getDprSrcSetSettingsByHeight(800, thumbnail?.mobileMedia?.height ?? 0)
          ),
        });

        // get alt text for relevant media asset (use mobile media if specified and device is Mobile)
        const deviceMedia = isMobile
          ? thumbnail?.mobileMedia ?? thumbnail?.media
          : thumbnail?.media;
        const { imageAltText } = parseMedia(deviceMedia);

        const asset = <Media altText={imageAltText} imgSrcs={imgSrcs} />;

        return (
          <AvalonCard
            key={sys.id}
            mediaAlignment={i % 2 === 0 ? Alignment.Right : Alignment.Left}
            title={title}
            media={asset}
            body={renderRichText(description)}
          />
        );
      })}
    </Block>
  );
};
