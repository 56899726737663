import { css } from '@emotion/css';
import { m, mobileMediaQuery } from '@snapchat/snap-design-system-marketing';

const backgroundStyles = `
  background: linear-gradient(
    108deg,
    rgba(32, 32, 32, 0.6) 0.87%,
    rgba(44, 43, 43, 0.66) 50.46%,
    rgba(42, 42, 42, 0.16) 100.05%
  );
  backdrop-filter: blur(8px);
`;

export const containerCss = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-xl')};
  justify-content: center;
  /* when combined with parent .sdsm-block-children grid-row-gap this ends up being 64px between elements */
  padding-bottom: ${m('--spacing-xl')};

  /* Don't apply padding to final element */
  &:last-child {
    padding-bottom: 0;
  }

  ${mobileMediaQuery} {
    gap: ${m('--spacing-l')};
  }
`;

export const mediaCss = css`
  aspect-ratio: 16 / 9;
  height: 100%;
  object-fit: cover;
  user-select: none;
  width: 100%;

  ${mobileMediaQuery} {
    border-radius: ${m('--border-radius-l')};
  }
`;

export const fourThreeAspectMediaCss = css`
  aspect-ratio: 4 / 3;
`;

export const valuePropsContentCss = css`
  border: 1px solid rgba(238, 238, 238, 0.2);
  border-radius: ${m('--border-radius-l')};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${mobileMediaQuery} {
    ${backgroundStyles}
  }
`;

export const valuePropsTitleCss = css`
  text-align: center;
`;

export const valuePropsBodyCss = css`
  align-self: stretch;
  ${backgroundStyles}
  bottom: 0;
  display: flex;
  flex-direction: row;
  gap: ${m('--spacing-xxxl')};
  padding: 32px 32px 48px;
  position: absolute;
  width: 100%;

  ${mobileMediaQuery} {
    background: none;
    backdrop-filter: none;
    flex-direction: column;
    gap: ${m('--spacing-s')};
    padding: 24px 16px;
    position: relative;
  }
`;

export const valuePropsBodyContentCss = css`
  flex: 1 0 0;
  line-height: 32px;
  ${mobileMediaQuery} {
    line-height: 24px;
  }
`;
