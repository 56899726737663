import { gql } from 'graphql-tag';

import { fragments as imageFragments } from '../../../../components/Image';
import { fragments as videoFragments } from '../../../../components/Video';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const fixedPositionBlockFragment = gql`
  fragment FixedPositionBlockAll on FixedPositionBlock {
    ...ContentfulSysId
    backgroundMedia {
      __typename
      ... on Video {
        ...VideoAll
      }
      ... on Image {
        ...ImageAll
      }
    }
    body {
      json
    }
    ctaCollection(limit: 2) {
      __typename
      items {
        ...ContentfulSysId
      }
    }
    desktopAlignment
    mobileAlignment
    title
  }
  ${contentfulSysIdFragment}
  ${imageFragments.all}
  ${videoFragments.all}
`;

export const fixedPositionBlockQuery = gql`
  query FixedPositionBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    fixedPositionBlock(preview: $preview, locale: $locale, id: $id) {
      ...ContentfulSysId
      ...FixedPositionBlockAll
    }
  }
  ${contentfulSysIdFragment}
  ${fixedPositionBlockFragment}
`;
