import { gql } from '@apollo/client';

import { fragments as imageFragments } from '../../../../components/Image';
import { fragments as videoFragments } from '../../../../components/Video';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const avalonProductHeroQuery = gql`
  query AvalonProductHeroQuery($id: String!, $preview: Boolean!, $locale: String!) {
    avalonProductHero(id: $id, preview: $preview, locale: $locale) {
      ...ContentfulSysId
      title
      body {
        json
      }
      textAlignment
      media {
        __typename
        ... on Video {
          ...VideoAll
        }
        ... on Image {
          ...ImageAll
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${imageFragments.all}
  ${videoFragments.all}
`;
