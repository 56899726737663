import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const avalonDeepLinkBlockQuery = gql`
  query AvalonDeepLinkBlockQuery($id: String!, $preview: Boolean!, $locale: String!) {
    avalonDeepLinkBlock(id: $id, preview: $preview, locale: $locale) {
      ...ContentfulSysId
      targetUrl
      persistUtmParameters
    }
  }
  ${contentfulSysIdFragment}
`;

export interface AvalonDeepLinkBlock {
  avalonDeepLinkBlock: ContentfulTypedSysProps<'AvalonDeepLinkBlock'> & {
    targetUrl: string;
    persistUtmParameters: boolean;
  };
}
