import { gql } from 'graphql-tag';

import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import { mosaicGridContentFragment } from '../MosaicGridCard/MosaicGridCard.query';

export const mosaicGridBlockFragment = gql`
  fragment MosaicGridBlockAll on MosaicGridBlock {
    ...ContentfulSysId
    title {
      json
    }
    subtitle {
      json
    }
    contentsCollection(limit: 10) {
      items {
        ...ContentfulSysId
        ...MosaicGridCardAll
      }
    }
  }
  ${contentfulSysIdFragment}
  ${mosaicGridContentFragment}
`;

export const mosaicGridBlockQuery = gql`
  query MosaicGridBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    mosaicGridBlock(preview: $preview, locale: $locale, id: $id) {
      ...ContentfulSysId
      ...MosaicGridBlockAll
    }
  }
  ${contentfulSysIdFragment}
  ${mosaicGridBlockFragment}
`;
