import { css } from '@emotion/css';
import {
  m,
  mediaQueryForRange,
  mobileMaxWidth,
  mobileMediaQuery,
  nonMobileMediaQuery,
  twoColumnCollapseWidth,
} from '@snapchat/snap-design-system-marketing';

// Custom media query for design between 768 >= 1024
const nonMobileNonDesktopMediaQuery = mediaQueryForRange({
  min: mobileMaxWidth + 1,
  max: twoColumnCollapseWidth,
});

export const mediaRightClassName = 'media-right';

// Taken from design. JIRA: https://jira.sc-corp.net/browse/WEBP-9432
const rightImageGradientCss = css`
  background: linear-gradient(
    108.11deg,
    rgba(32, 32, 32, 0.6) 0.87%,
    rgba(44, 43, 43, 0.66) 50.46%,
    rgba(42, 42, 42, 0.16) 100.05%
  );
`;

const leftImageGradientCss = css`
  background: linear-gradient(
    108.11deg,
    rgba(32, 32, 32, 0.8) 0.87%,
    rgba(44, 43, 43, 0.8) 52.43%,
    rgba(42, 42, 42, 0.16) 100.05%
  );
`;

export const wrapperCss = css`
  border-radius: ${m('--border-radius-l')};
  border: 1px solid rgba(238, 238, 238, 0.2);
  display: flex;
  overflow: hidden;

  ${mobileMediaQuery} {
    flex-direction: column;
    width: 100%;
  }

  ${nonMobileMediaQuery} {
    &.${mediaRightClassName} {
      flex-direction: row-reverse;
    }

    ${leftImageGradientCss}
  }
`;

const mediaCss = css`
  aspect-ratio: 16 / 9;
  display: block;
  height: 100%;
  object-fit: cover;
  user-select: none;
  width: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const mediaWrapperCss = css`
  ${nonMobileMediaQuery} {
    width: 60%;
  }

  /** nonMobileNonDesktopMediaQuery should come after nonMobileMediaQuery */
  ${nonMobileNonDesktopMediaQuery} {
    width: 50%;
  }

  picture,
  video,
  img {
    ${mediaCss}
  }
`;

export const textWrapperCss = css`
  html[dir='rtl'] &,
  body[dir='rtl'] & {
    direction: rtl;
  }

  ${mobileMediaQuery} {
    ${rightImageGradientCss}
    padding-block: ${m('--spacing-m')} ${m('--spacing-l')};
    padding-inline: ${m('--spacing-m')};
  }

  ${nonMobileMediaQuery} {
    flex: 0 0 40%;
    padding: ${m('--spacing-xl')} ${m('--spacing-xxxl')};
    align-content: center;
  }

  /** nonMobileNonDesktopMediaQuery should come after nonMobileMediaQuery */
  ${nonMobileNonDesktopMediaQuery} {
    flex: 0 0 50%;
  }
`;

export const bodyTextCss = css`
  ${mobileMediaQuery} {
    margin-block-start: ${m('--spacing-xs')};
  }

  ${nonMobileMediaQuery} {
    margin-block-start: ${m('--spacing-m')};
  }
`;
