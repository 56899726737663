import { BrowserFeaturesContext, useIsMobile } from '@snapchat/snap-design-system-marketing';
import { type FC, useContext } from 'react';

import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { AvalonNoScrollBlock } from './AvalonNoScrollBlock';
import { AvalonScrollBlock } from './AvalonScrollBlock';
import { avalonScrollSectionQuery } from './AvalonScrollSection.query';
import type { AvalonScrollDataHandlerProps } from './AvalonScrollSection.types';

export const AvalonScrollSection: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<AvalonScrollDataHandlerProps, ContentfulIdVariable>(
    avalonScrollSectionQuery,
    { variables: { id: props.id } }
  );
  const isMobile = useIsMobile();

  // parse client hints and disable animation if specified
  const browserFeatures = useContext(BrowserFeaturesContext);
  const reduceMotion = !!browserFeatures.getCachedHighEntropyHints()?.reduceMotion;

  if (!data?.avalonScrollSection) return null;

  const { textNodesCollection, title, subtitle, ...rest } = data.avalonScrollSection;
  const textNodes = textNodesCollection?.items;

  // Disable the animation on mobile devices or if we detect a hint to reduce motion
  if (reduceMotion || isMobile) {
    return <AvalonNoScrollBlock title={title} subtitle={subtitle} textNodes={textNodes} />;
  }

  return <AvalonScrollBlock textNodes={textNodes} title={title} subtitle={subtitle} {...rest} />;
};
