import { css } from '@emotion/css';
import { mobileMediaQuery } from '@snapchat/snap-design-system-marketing';

import { totalHeaderHeightCssVar } from '../../../../components/Header/headerSizeUtils';

export const heroCss = css`
  display: flex;
  height: 100vh;
  height: 100dvh;
  justify-content: center;
  margin-top: calc(0px - var(${totalHeaderHeightCssVar}));
  position: relative;

  ${mobileMediaQuery} {
    height: auto;
    width: 100vw;
    aspect-ratio: 4/3;
    overflow: hidden;
  }
`;

export const imageContainerCss = css`
  display: block;
  height: 100%;
  width: 100%;
`;

export const imageCss = css`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const headingCss = css`
  border: 0 none;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const scrollContainerCss = css`
  bottom: 18px;
  cursor: pointer;
  height: 32px;
  position: absolute;
`;
