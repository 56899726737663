import { css, keyframes } from '@emotion/css';
import { Block, m } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

const shiftBackgroundXKeyframes = keyframes`
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
`;

const loadingCss = css`
  /* Placeholder has no size, so we need to set a size for the animation */
  min-height: 600px;

  background: linear-gradient(113deg, #222 0%, #333 50%, #111 100%);
  border-radius: ${m('--border-radius-l')};
  border: 1px solid rgba(238, 238, 238, 0.2);
  animation: ${shiftBackgroundXKeyframes} 2s linear infinite;
  /* We make the background width 400% so that we can move it back and forth */
  background-size: 400% 100%;
`;

export const LoadingPlaceholder: FC = () => {
  return (
    <Block>
      <div className={loadingCss} data-test-id="loading" />
    </Block>
  );
};
