import { css } from '@emotion/css';
import {
  largeDesktopMediaQuery,
  m,
  mobileMediaQuery,
  nonMobileMediaQuery,
} from '@snapchat/snap-design-system-marketing';

import { totalHeaderHeightCssVar } from '../../../../components/Header/headerSizeUtils';

export const blockContainerCss = css`
  height: calc(100vh - var(${totalHeaderHeightCssVar}));
  position: relative;

  /* For portrait desktop devices */
  ${nonMobileMediaQuery} and (orientation: portrait) {
    /** enforce a reasonable max height */
    max-height: 900px;
  }
`;

export const blockPositionCss = css`
  display: flex;
  inset: 0;
  padding: 80px;
  position: absolute;

  ${mobileMediaQuery} {
    padding: ${m('--spacing-xl')};
  }
`;

export const blockContentCss = css`
  ${nonMobileMediaQuery} {
    width: 80%;
  }

  ${largeDesktopMediaQuery} {
    width: 40%;
  }

  ${mobileMediaQuery} {
    width: inherit;
  }
`;

export const cardContainerCss = css`
  backdrop-filter: blur(5px);
  background: linear-gradient(
    130.29deg,
    rgba(32, 32, 32, 0.6) -15.44%,
    rgba(44, 43, 43, 0.9) 45.29%,
    rgba(42, 42, 42, 0.32) 101.37%
  );
  border-radius: ${m('--spacing-m')};
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-l')};
  overflow: hidden;
  padding: ${m('--spacing-xl')};
  width: 100%;

  ${mobileMediaQuery} {
    padding: ${m('--spacing-xl')} ${m('--spacing-m')};
  }
`;

export const cardContentCss = css`
  color: ${m('--foreground-color')};
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-xs')};
`;

export const cardCtasCss = css`
  display: flex;
  flex-wrap: wrap;
  gap: ${m('--spacing-s')};
`;

export const alignMiddleCss = css`
  align-items: center;
`;

export const alignMiddleMobileCss = css`
  ${mobileMediaQuery} {
    align-items: center;
  }
`;

export const alignBottomCss = css`
  align-items: flex-end;
`;

export const alignBottomMobileCss = css`
  ${mobileMediaQuery} {
    align-items: flex-end;
  }
`;

export const justifyCenterCss = css`
  justify-content: center;
`;

export const justifyRightCss = css`
  justify-content: flex-end;
`;

export const imageCss = css`
  height: 100%;
  object-fit: cover;
  user-select: none;
  width: 100%;
`;
