import { BackgroundColor, Block as BlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC, ReactElement } from 'react';

import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { renderRichText } from '../../../../utils/renderText/renderRichText';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder';
import { MosaicGridCard } from '../MosaicGridCard/MosaicGridCard';
import type { MosaicBlockContentDataHandlerProps } from '../MosaicGridCard/MosaicGridCard.query';
import { mosaicGridBlockQuery } from './MosaicGridBlock.query';

export const MosaicGridBlock: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<MosaicBlockContentDataHandlerProps, ContentfulIdVariable>(
    mosaicGridBlockQuery,
    {
      variables: { id: props.id },
    }
  );

  if (!data?.mosaicGridBlock) return <LoadingPlaceholder />;

  const { title, anchor, subtitle, contentsCollection } = data.mosaicGridBlock;

  let childrenContents: ReactElement[] = [];

  if (contentsCollection?.items.length) {
    childrenContents = contentsCollection.items.map((item, index) => (
      <MosaicGridCard key={item.sys.id} {...item} isFirst={index === 0} />
    ));
  }

  return (
    <BlockSDS
      className={'avalon-mosaic-grid-block'}
      anchorId={anchor}
      backgroundColor={BackgroundColor.Black}
      title={renderRichText(title)}
      subtitle={renderRichText(subtitle)}
      titleAlignmentMobile="Center"
      maxColumns={1}
    >
      {childrenContents}
    </BlockSDS>
  );
};
