import { cx } from '@emotion/css';
import { Alignment, textAlignmentCss } from '@snapchat/snap-design-system-marketing';
import type { FC, ReactNode } from 'react';

// eslint-disable-next-line import/no-namespace
import * as styles from './AvalonCard.css';

export interface AvalonCardProps {
  className?: string;
  mediaAlignment?: Alignment;
  textAlignment?: Alignment;
  media: ReactNode;
  title: ReactNode;
  body: ReactNode;
}

/** Simple avalon-styled card; used for mobile display and other cards. */
export const AvalonCard: FC<AvalonCardProps> = props => {
  return (
    <article
      className={cx(
        'avalon-card',
        styles.wrapperCss,
        { [styles.mediaRightClassName]: props.mediaAlignment === Alignment.Right },
        props.className
      )}
    >
      <div className={styles.mediaWrapperCss}>{props.media}</div>
      <div
        className={cx(
          styles.textWrapperCss,
          props.textAlignment ? textAlignmentCss[props.textAlignment] : undefined
        )}
      >
        <h4>{props.title}</h4>
        <div className={styles.bodyTextCss}>{props.body}</div>
      </div>
    </article>
  );
};
