import { gql } from '@apollo/client';
import type { Asset } from '@snapchat/mw-contentful-schema';

import type { ImageDataProps } from '../../../../components/Image';
import { fragments as imageFragments } from '../../../../components/Image';
import { assetFragment } from '../../../../components/Media';
import type { ContentfulSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import type { RichText } from '../../../../types/RichText';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

const avalonScrollTextNodeFragment = gql`
  fragment AvalonScrollTextNodeAll on AvalonScrollTextNode {
    ...ContentfulSysId
    title
    description {
      json
    }
    top
    left
    right
    bottom
    keyFrames
    thumbnail {
      __typename
      ...ImageAll
    }
  }
  ${contentfulSysIdFragment}
  ${imageFragments.all}
`;

export const avalonScrollSectionQuery = gql`
  query AvalonScrollSectionQuery($id: String!, $preview: Boolean!, $locale: String!) {
    avalonScrollSection(id: $id, preview: $preview, locale: $locale) {
      ...ContentfulSysId
      title
      subtitle {
        json
      }
      scrollHeight
      mp4Source {
        ...AssetAll
      }
      webmSource {
        ...AssetAll
      }
      scrubStart
      scrubEnd
      videoStart
      videoEnd
      textNodesCollection(limit: 5) {
        items {
          ...AvalonScrollTextNodeAll
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${assetFragment}
  ${avalonScrollTextNodeFragment}
`;

export interface AvalonScrollTextNode extends ContentfulSysProps {
  contentfulDescription?: string;
  title?: string;
  description?: RichText;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  keyFrames?: string;
  thumbnail?: ImageDataProps;
}

export interface AvalonScrollSection extends ContentfulSysProps {
  contentfulDescription?: string;
  title?: string;
  subtitle?: RichText;
  mp4Source?: Asset;
  scrubEnd?: number;
  scrubStart?: number;
  videoEnd?: number;
  videoStart?: number;
  webmSource?: Asset;
  scrollHeight?: number;
  textNodesCollection?: Items<AvalonScrollTextNode>;
}
