import { cx } from '@emotion/css';
import {
  Block,
  ScrollAnimatedSection as ScrollableSectionSDS,
  ScrollAnimatedVideo,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { getHeaderHeightFromCssVar } from '../../../../components/Header/headerSizeUtils';
import { renderRichTextMultiLineWithMarkings } from '../../../../utils/renderText/renderRichText';
import {
  innerScrollContainerCss,
  outerScrollContainerCss,
  scrubVideoCss,
  textFrameCss,
} from './AvalonScrollSection.styles';
import type { AvalonScrollBlockProps } from './AvalonScrollSection.types';
import { AvalonScrollTextNode } from './AvalonScrollTextNode';

/** CSS Variable used to track scroll progress. Used to share state between components. */
export const avalonScrollProgressVar = '--avalon-scroll-progress';

/** Custom component responsible for rendering scroll animated video */
export const AvalonScrollBlock: FC<AvalonScrollBlockProps> = ({
  title,
  subtitle,
  mp4Source,
  webmSource,
  scrollHeight = 1,
  scrubStart,
  scrubEnd,
  videoStart,
  videoEnd,
  textNodes,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const totalHeaderHeightRef = useRef<number>(64);

  // gets header height value from css var. only need to do this once
  // until we allow variable header heights... one day...
  // when that time comes we will have a hook w/ resize observer to fix this
  useEffect(() => {
    totalHeaderHeightRef.current = getHeaderHeightFromCssVar();
  }, []);

  /**
   * Function that returns the scroll progress for the scrub video. This returns the percentage of
   * the scrollable section that has scrolled past the top of the screen.
   */
  const calculateScrollProgress = useCallback(() => {
    if (!containerRef.current) {
      return 0;
    }
    const top =
      (containerRef.current.getBoundingClientRect().top - totalHeaderHeightRef.current) * -1;

    const totalHeight = containerRef.current.offsetHeight - window.innerHeight;
    const scrollProgress = top / totalHeight;

    // update shared scroll progress state
    let roundedScrollProgress = scrollProgress.toFixed(3);
    if (scrollProgress < 0) roundedScrollProgress = '0';
    if (scrollProgress > 1) roundedScrollProgress = '1';

    containerRef.current.style.setProperty(avalonScrollProgressVar, roundedScrollProgress);

    return scrollProgress;
  }, []);

  const heightStyle = useMemo(() => {
    return {
      [avalonScrollProgressVar]: 0,
      height: `${scrollHeight * 100}vh`,
    };
  }, [scrollHeight]);

  const BackgroundVideo: FC = () => {
    if (!mp4Source?.url || !webmSource?.url) return null;

    return (
      <ScrollAnimatedVideo
        calculateScrollProgress={calculateScrollProgress}
        className={cx('avalon-scroll-animated-video', scrubVideoCss)}
        scrubEnd={scrubEnd}
        scrubStart={scrubStart}
        videoEnd={videoEnd}
        videoMp4Src={mp4Source.url}
        videoStart={videoStart}
        videoWebmSrc={webmSource.url}
      />
    );
  };

  return (
    <>
      {(title || subtitle) && (
        <Block
          className="avalon-scroll-block-title"
          title={title}
          subtitle={renderRichTextMultiLineWithMarkings(subtitle)}
          titleAlignmentMobile="Center"
        />
      )}
      <ScrollableSectionSDS
        containerRef={containerRef}
        style={heightStyle}
        className={cx('avalon-scroll-section', outerScrollContainerCss)}
        innerContainerClassName={innerScrollContainerCss}
      >
        <BackgroundVideo />
        <div className={textFrameCss}>
          {textNodes?.map(node => {
            return <AvalonScrollTextNode key={node.sys.id} {...node} />;
          })}
        </div>
      </ScrollableSectionSDS>
    </>
  );
};
