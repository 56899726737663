import { css, cx, keyframes } from '@emotion/css';
import type { CSSProperties, FC } from 'react';
import { useMemo } from 'react';

import { renderRichText } from '../../../../utils/renderText/renderRichText';
import { avalonScrollProgressVar } from './AvalonScrollBlock';
import { textNodeCss } from './AvalonScrollSection.styles';
import type { AvalonScrollTextNodeProps } from './AvalonScrollSection.types';

/** Custom component responsible for rendering Value Prop text nodes over the scrollable canvas */
export const AvalonScrollTextNode: FC<AvalonScrollTextNodeProps> = ({
  title,
  description,
  top,
  left,
  right,
  bottom,
  keyFrames,
}) => {
  // edge case: if no positioning data specified, default to anchoring at top left.
  if (!top && !left && !right && !bottom) {
    top = '5%';
    left = '5%';
  }

  // NOTE: uses CSSProperties since this is faster than generating via Emotion
  const positionStyles = useMemo((): CSSProperties => {
    const position: CSSProperties = {};

    if (top) position.top = top;
    if (left) position.left = left;
    if (right) position.right = right;
    if (bottom) position.bottom = bottom;

    return position;
  }, [top, left, right, bottom]);

  // Generated via emotion since we are leveraging keyframes.  Performance impact should be minimal.
  const animationCss = useMemo((): string => {
    if (!keyFrames) return '';

    const animation = keyframes(keyFrames);

    const animationCss = css`
      animation: ${animation} 1s linear infinite;
      animation-play-state: paused;
      animation-delay: calc(var(${avalonScrollProgressVar}) * -1s);
      animation-iteration-count: 1;
      animation-fill-mode: 'both';
      transform-style: 'preserve-3d';
    `;

    return animationCss;
  }, [keyFrames]);

  return (
    <div
      className={cx('avalon-scroll-text-node', textNodeCss, animationCss)}
      style={positionStyles}
    >
      <h4>{title}</h4>
      <div>{renderRichText(description)}</div>
    </div>
  );
};
