import { Block as BlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder';
import { ValuePropsContent } from '../ValuePropsContent/ValuePropsContent';
import type { ValuePropsBlockDataHandlerProps } from './types';
import { valuePropsBlockQuery } from './ValuePropsBlock.query';

export const ValuePropsBlock: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<ValuePropsBlockDataHandlerProps, ContentfulIdVariable>(
    valuePropsBlockQuery,
    {
      variables: { id: props.id },
    }
  );

  if (!data?.valuePropsBlock) return <LoadingPlaceholder />;

  const { anchor, contentsCollection, backgroundColor = 'Black' } = data.valuePropsBlock;

  return (
    <BlockSDS
      className="avalon-value-props-block"
      anchorId={anchor}
      backgroundColor={backgroundColor}
      maxColumns={1}
      titleAlignmentMobile="Center"
    >
      {contentsCollection.items.map(item => (
        <ValuePropsContent key={item.sys.id} {...item} />
      ))}
    </BlockSDS>
  );
};
