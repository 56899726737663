import { css } from '@emotion/css';
import {
  largeDesktopMediaQuery,
  m,
  mobileMediaQuery,
  nonMobileMediaQuery,
} from '@snapchat/snap-design-system-marketing';

export const heroContainerCss = css`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-xxxxl')};
  margin-block-start: ${m('--spacing-xxxxl')};
  overflow: hidden;

  ${mobileMediaQuery} {
    gap: ${m('--spacing-xl')};
    margin-block-start: ${m('--spacing-xl')};
  }
`;

/** Value taken from designs. Jira ticket: https://jira.sc-corp.net/browse/WEBP-9434 */
const contentMaxWidthPx = 968;

export const heroContentCss = css`
  align-self: center;
  color: ${m('--foreground-color')};
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  font-size: ${m('--p1-desktop-font-size')};
  gap: ${m('--spacing-xl')};
  line-height: ${m('--p1-desktop-font-line-height')};
  padding: ${m('--spacing-m')} ${m('--spacing-xl')};

  ${nonMobileMediaQuery} {
    max-width: 100%;
  }

  ${largeDesktopMediaQuery} {
    max-width: ${contentMaxWidthPx}px;
  }

  ${mobileMediaQuery} {
    font-size: ${m('--p1-mobile-font-size')};
    gap: ${m('--spacing-m')};
    line-height: ${m('--p1-mobile-font-line-height')};
    max-width: 100%;
  }
`;

export const textCenterCss = css`
  text-align: center;
`;

export const textEndCss = css`
  text-align: end;
`;

export const heroTitleCss = css`
  font-size: ${m('--h1-desktop-font-size')};
  overflow-wrap: break-word;
  text-transform: uppercase;

  ${mobileMediaQuery} {
    font-size: ${m('--h1-mobile-font-size')};
  }
`;

export const mediaContainerCss = css`
  flex: 1 1 auto;
`;

export const mediaAspectRatioCss = css`
  aspect-ratio: 16 / 9;
`;

export const imageCss = css`
  height: 100%;
  object-fit: cover;
  user-select: none;
  width: 100%;
  display: block;
`;

export const imagePictureCss = css`
  display: block;
  aspect-ratio: 16 / 9;
`;
