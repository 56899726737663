import { type FC, useContext, useEffect } from 'react';

import { AppContext } from '../../../../AppContext';
import { Config } from '../../../../config';
import { mergeUrlParameters } from '../../../../helpers/uri';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import type { AvalonDeepLinkBlock as AvalonDeepLinkBlockType } from './AvalonDeepLinkBlock.query';
import { avalonDeepLinkBlockQuery } from './AvalonDeepLinkBlock.query';

/**
 * Does not render a UX element, but will trigger deep link navigation if rendered on page.
 *
 * Implementing this as a Block component rather than a custom hook because this is a custom
 * behavior for Avalon.
 */
export const AvalonDeepLinkBlock: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<AvalonDeepLinkBlockType, ContentfulIdVariable>(
    avalonDeepLinkBlockQuery,
    { variables: { id: props.id } }
  );

  const { getCurrentUrl } = useContext(AppContext);

  // Only attempt this on Client render
  useEffect(() => {
    if (!data?.avalonDeepLinkBlock?.targetUrl) return;

    const url = new URL(data?.avalonDeepLinkBlock?.targetUrl);

    if (data?.avalonDeepLinkBlock?.persistUtmParameters) {
      mergeUrlParameters(url, new URL(getCurrentUrl()), Config.persistentQueryParams);
    }

    // attempt to open deep link
    window.location.href = url.href;
  }, [
    data?.avalonDeepLinkBlock?.targetUrl,
    data?.avalonDeepLinkBlock?.persistUtmParameters,
    getCurrentUrl,
  ]);

  return null;
};
