import { gql } from 'graphql-tag';

import { ctaFragment } from '../../../../components/CallToAction';
import { fragments as imageFragments } from '../../../../components/Image';
import { fragments as videoFragments } from '../../../../components/Video';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

const valuePropsContentFragment = gql`
  fragment ValuePropsContentAll on ValuePropsContent {
    title {
      json
    }
    subtitle
    body {
      json
    }
    mediaAspectRatio
    asset {
      ... on Video {
        ...VideoAll
      }
      ... on Image {
        ...ImageAll
      }
    }
    callToAction {
      ... on CallToAction {
        ...CallToActionAll
      }
    }
  }
  ${imageFragments.all}
  ${videoFragments.all}
  ${ctaFragment}
`;

const valuePropsBlockFragment = gql`
  fragment ValuePropsBlockAll on ValuePropsBlock {
    ...ContentfulSysId
    backgroundColor
    anchor
    contentsCollection(limit: 15) {
      items {
        ...ContentfulSysId
        ...ValuePropsContentAll
      }
    }
  }
  ${contentfulSysIdFragment}
  ${valuePropsContentFragment}
`;

export const valuePropsBlockQuery = gql`
  query ValuePropsBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    valuePropsBlock(preview: $preview, locale: $locale, id: $id) {
      ...ContentfulSysId
      ...ValuePropsBlockAll
    }
  }
  ${contentfulSysIdFragment}
  ${valuePropsBlockFragment}
`;
