// TODO: Improve this from source when that package becomes usable.
// import { storeHandler as ChatStore, Widget } from '@snapchat/snap-web-chat';
// Then use the code as:
// <Provider store={ChatStore}>
//   <Widget authToken="" buttonTheme="spectacles-v2" dontHideButton={true} />
// </Provider>

import { injectGlobal } from '@emotion/css';
import { ensureSingleScript } from '@snapchat/mw-common/client';
import { globalCss } from '@snapchat/snap-design-system-marketing';
import { type FC, useContext, useEffect } from 'react';

import { AppContext } from '../../../../AppContext';
import { useFeatureFlags } from '../../../../components/FeatureFlags';
import { Config } from '../../../../config';

declare global {
  interface Window {
    chatInit: (args: { theme: string; department: string; staging: boolean }) => void;
  }
}

export const AvalonChatWidget: FC = () => {
  const { currentLocale } = useContext(AppContext);
  const flags = useFeatureFlags();

  useEffect(() => {
    // Only english is supported.
    if (!currentLocale.startsWith('en')) return;

    // Show only if enabled on prod.
    if (Config.isDeploymentTypeProd && flags.avalonEnableChatWidget === 'false') return;

    injectGlobal(globalCss`
      #snapchat-web-chat-help-widget {
        font-family: Graphik, sans-serif !important;
      }
    `);

    ensureSingleScript(
      'chat-widget',
      // Compiled from: https://github.sc-corp.net/Snapchat/web-chat/blob/master/widget/package.json
      '/third-party/chat-widget-0.3.20.js',
      () => {
        window.chatInit({
          theme: 'spectacles-v2',
          staging: !Config.isDeploymentTypeProd,
          department: 'Spectacles',
        });
      },
      { defer: 'true' }
    );
  }, [currentLocale, flags.avalonEnableChatWidget]);

  return null;
};
